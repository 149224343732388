import { Container, Row, Col } from "react-bootstrap";
import logo from '../..//images/logo-kaili.png';
import { Fade } from "react-awesome-reveal";

function About() {

    return (
        <>
            <Fade>
                <Container className="mb-5 mt-5">
                    <Row>
                        <Col className="">
                            <div className="d-flex">
                                <div className="text-justify">
                                    <h2 className="mb-4 display-6 fw-bold text-center">¿Quiénes somos?</h2>
                                    <p className="display-6 fs-4 text-center">
                                        Somos un Centro Terapéutico Integral enfocado a la atención integral de Salud Mental en todo el ciclo vital,
                                        desde los más pequeños hasta personas mayores. Aquí encontrarás un lugar cálido y acogedor, con profesionales
                                        con vocación y comprometidos por el bienestar de las  personas y las familias que requieran apoyo, acompañamiento y
                                        atención.</p>
                                </div>

                            </div>

                        </Col>
                    </Row>
                    <Row className="mt-5"></Row>
                    <Row className="mt-5">
                        <Col md={8}>
                            <div className="d-flex">
                                <div className="text-left">
                                    <h2 className="mb-4 display-6 fw-bold text-center">¿Por qué el nombre Kaili y el logo?</h2>
                                    <p className="display-6 fs-4 text-center">Kaili en hawaiano significa deidad, lo que quiere decir que cada persona es un ser divino y tiene una esencia divina,
                                        es decir, todos somos únicos, irrepetibles, valiosos e importantes, y así debemos respetarnos. Esto nos hace mucho sentido porque nuestras atenciones están
                                        centradas para cada persona y familia en particular, considerando su historia de vida, sus creencias, valores, deseos, necesidades y preferencias, reconociendo y
                                        considerando su individualidad. El logo demuestra calidez, apoyo, cercanía, empatía y acompañamiento representado por un abrazo, de la manera en que nosotros
                                        queremos acogerlos y acompañarlos a ustedes.</p>
                                </div>

                            </div>
                        </Col>
                        <Col>
                            <img src={logo} alt="logo" style={{ width: '100%', marginTop: '25px' }} />
                        </Col>

                    </Row>
                    <Row className="justify-content-center">

                    </Row>
                </Container>
            </Fade>
        </>
    )
}

export default About;