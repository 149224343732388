import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home/home';
import KailiNavbar from './pages/navbar/navbar';
import Information from './components/information';
import Footer from './components/footer';
import logo from './images/logo-kaili.png'
import { Route, Routes } from 'react-router-dom';
import About from './pages/about/about';
import WhatWeDo from './pages/what-we-do/what-we-do';
import Contact from './pages/contact/contact';
import { useLoadScript } from '@react-google-maps/api';


function App() {

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ['places', 'map', 'marker'],
  });

  return (
    <>
        <Information />
        <KailiNavbar logo={logo} />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/que-hacemos' element={<WhatWeDo />} />
          <Route path='/contacto' element={<Contact />} />
        </Routes>
        <Footer />
    </>
  );
}

export default App;
