import { Container, Form } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import logo from '../../logo-kaili.png';
import Button from 'react-bootstrap/Button';
import './navbar.css';
import { Link } from 'react-router-dom';

function KailiNavbar({ logo }) {
  return (

    <Navbar expand="lg" className='bg-white'>
      <Container>
        <Navbar.Brand>
          <img src={logo} style={{ height: '20vmin' }} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
            <Nav className='m-auto'>
              <div className="mr-2">
                <Nav.Link as={Link} to='/'>
                  <h1 className='fs-4' style={{ color: 'black' }}>Inicio</h1></Nav.Link>
              </div>
              <div className='mr-2'>
                <Nav.Link as={Link} to='/about'>
                  <h1 className='fs-4' style={{ color: 'black' }}>¿Quiénes somos?</h1></Nav.Link>
              </div>
              <div className='mr-2'>
                <Nav.Link as={Link} to='/que-hacemos'>
                  <h1 className='fs-4' style={{ color: 'black' }}>¿Qué hacemos?</h1></Nav.Link>
              </div>
              <div className='mr-2'>
                <Nav.Link as={Link} to='/contacto'>
                  <h1 className='fs-4' style={{ color: 'black' }}>Contacto</h1></Nav.Link>
              </div>
            </Nav>
        </Navbar.Collapse>
        <Form className="d-flex">
          <Button className='custom-button btn-lg'>
            <a href="https://api.whatsapp.com/message/WQHB6Z6KSCPOJ1?autoload=1&app_absent=0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
              RESERVAR HORA
            </a>
          </Button>
        </Form>
      </Container>
    </Navbar>
  );
}

export default KailiNavbar;
