import React from 'react';
import './home.css'; // Asegúrate de importar tus estilos CSS
import Card from 'react-bootstrap/Card';
import { Container, Row, Col, Button } from 'react-bootstrap';
import imagenInstagram1 from '../../images/imagen-1.png';
import imagenInstagram2 from '../../images/imagen-2.png';
import imagenInstagram3 from '../../images/imagen-3.png';
import imagenFono from '../../images/fono-imagen-home.jpeg'
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imagenTO from '../../images/nina-teo-home.jpeg'
import { Fade } from 'react-awesome-reveal';

function Home() {
    return (


        <>
            <Fade>
                <Row>
                    <div className="hero-image">
                        <div className="hero-text">
                            <h1 className='display-4 fw-bold text-sm-start text-md-start text-lg-start text-xl-start'>¡Bienvenidos a Centro Kaili!</h1>
                            <p className='fs-2 fs-md-4 fs-lg-3'>Centro Terapéutico Integral para todo el ciclo vital</p>
                            <p className='fs-3 fs-md-4 fs-lg-3'>¿Tienes dudas o preguntas?</p>
                            <Button className='custom-button btn-lg text-black'>
                                <a href="https://api.whatsapp.com/message/WQHB6Z6KSCPOJ1?autoload=1&app_absent=0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    CONSULTA AQUÍ
                                </a>
                            </Button>
                        </div>
                    </div>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-3 fw-bold' style={{ color: '#219ebc' }}>Nuestros servicios</h2>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Container className='mt-5'>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex justify-content-center'>
                            <Card className="h-100 card-custom">
                                <Card.Img variant="top" src={imagenTO} width="100%" height="auto" />
                                <Card.Body style={{ backgroundColor: '#f4e285' }}>
                                    <Card.Title className='fs-4 fw-bold text-center'>Evaluación e intervención de Terapia Ocupacional</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex justify-content-center'>
                            <Card className="h-100 card-custom">
                                <Card.Img variant="top" src={imagenFono} width="100%" height="auto" />
                                <Card.Body style={{ backgroundColor: '#f4e285' }}>
                                    <Card.Title className='fs-4 fw-bold text-center'>Evaluación e intervención de Fonoaudiología</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {/* <Row className='mt-5'></Row>
            <Row className='mt-5'></Row> */}

                {/* <Row className='mt-5'>
                <h2 className='text-center display-5 fw-bold' style={{ color: '#219ebc' }}>Consulta por nuestros planes en las <b>sesiones de intervención</b></h2>
            </Row>

            <Row className='mt-5'></Row>
            <Row className='mt-5'></Row> */}

                {/* <Container>
                <Row className='mt-5 text-center'>
                    <Col xs={12} md={4} lg={4} className='mx-auto justify-content-center mb-3' style={{ backgroundColor: '#66D5E5', padding: '15px', borderRadius: '50px' }}>
                        <h1 className='fs-2 fw-bold'>Pack de 4 sesiones al mes</h1>
                    </Col>
                    <Col xs={12} md={4} lg={4} className='mx-auto justify-content-center mb-3' style={{ backgroundColor: '#66D5E5', padding: '15px', borderRadius: '50px' }}>
                        <h1 className='fs-2 fw-bold'>Pack de 8 sesiones al mes</h1>
                    </Col>
                </Row>
            </Container> */}

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-3 fw-bold' style={{ color: '#219ebc' }}>Modalidad de atención</h2>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Container>
                    <Row className='mt-5 text-center'>
                        <Col xs={12} md={4} lg={4} className='mx-auto justify-content-center mb-3' style={{ backgroundColor: '#66D5E5', padding: '15px', borderRadius: '50px' }}>
                            <h1 className='fs-3'>Atención presencial en centro terapéutico a pasos del metro Manquehue</h1>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mx-auto justify-content-center mb-3' style={{ backgroundColor: '#66D5E5', padding: '15px', borderRadius: '50px' }}>
                            <h1 className='fs-3'>Atención online a personas desde los 15 años en adelante</h1>
                        </Col>
                    </Row>
                </Container>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-3 fw-bold' style={{ color: '#219ebc' }}>Nuestro contenido en Instagram</h2>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5 justify-content-center'>
                    <Col md={3}>
                        <Card>
                            <a href='https://www.instagram.com/p/C3QGyIzuepp/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram1} />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <a href='https://www.instagram.com/p/C3ctkQNuE-y/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram3} />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <a href='https://www.instagram.com/p/C30kPqzpmUe/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram2} />
                            </a>
                        </Card>
                    </Col>
                </Row>

                <Row className='mt-5'></Row>

                <Row className='mt-3 mb-5 justify-content-center'>
                    <Col xs="auto">
                        <Button variant="info" size='lg' href="https://www.instagram.com/centro.kaili/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="mr-2" /> Síguenos en Instagram
                        </Button>
                    </Col>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>
            </Fade>
        </>
    );
}

export default Home;
